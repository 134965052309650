<template>
  <div class="grid h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-10">
    <div
      class="p-4 md:py-10 md:px-12 order-last md:order-none md:col-span-2 lg:col-span-6 w-full overflow-y-auto"
    >
      <TheLoader v-if="loading" size="xlarge" />
      <component
        :is="Form"
        v-else
        class=""
        :with-close-button="isOpenModal"
        @close="close('contact')"
      />
    </div>

    <div
      class="relative h-[200px] overflow-hidden rounded-t-lg md:h-auto md:rounded-l-none md:rounded-r-lg lg:col-span-4"
    >
      <picture class="size-full max-h-full max-w-full">
        <source
          media="(min-width:768px)"
          :srcset="image.desktop"
          class="h-full w-full"
        />
        <source
          media="(max-width:767px)"
          :srcset="image.mobile"
          class="h-full w-full"
        />
        <img
          :src="image.desktop"
          class="h-full w-full object-cover"
          alt="subscriptions"
        />
      </picture>
    </div>
  </div>
</template>

<script setup lang="ts">
/** components */
import UnAuthContactForm from '~/components/forms/contact/UnAuthForm.vue'
import AuthContactForm from '~/components/forms/contact/AuthForm.vue'

/** composable */
import { useModalStore } from '~/stores/modal'
import useAuthentication from '~/composables/useAuthentication'

/** utils */
import { cloudinaryBuildRoute } from '~/src/utils/cloudinary'

/** props */
withDefaults(
  defineProps<{
    loading: boolean
  }>(),
  {
    loading: false,
  },
)

const modalStore = useModalStore()
const { loggedIn } = useAuthentication()

const { close } = modalStore
const { modals } = storeToRefs(modalStore)

const isOpenModal = computed(() => modals.value.contactOpen)

const image = ref({
  desktop: cloudinaryBuildRoute(
    'v1678221022/assets/login/login-1_vp84jy.jpg',
    ['w_500'],
    true,
    ['c_fill', 'h_750'],
  ),
  mobile: cloudinaryBuildRoute(
    'v1678221022/assets/login/login-1_vp84jy.jpg',
    ['w_720'],
    true,
    ['c_fill', 'h_200'],
  ),
})

const Form = computed(() =>
  loggedIn.value ? AuthContactForm : UnAuthContactForm,
)
</script>
